<template>
  <div>
    <h3>
      {{ org.name }}
    </h3>
    <el-form
      ref="form"
      v-loading="loading"
      label-width="200px"
      :model="form"
      :rules="rules"
    >
      <el-tabs>
        <el-tab-pane label="Organization Detail">
          <el-form-item
            class="org-detail"
            label="Organization Name"
            prop="name"
          >
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item
            class="org-detail"
            label="Parent Organization"
            prop="parentOrganization"
          >
            <org-picker :organization.sync="form.parent_organization" />
          </el-form-item>
          <el-form-item
            v-if="false"
            class="org-detail"
            label="Disable Organization"
          >
            <el-checkbox v-model="form.disabled" />
          </el-form-item>
          <!-- <el-form-item
            class="org-detail"
            label="Web Pixel Org ID"
            prop="pixelOrganizationId"
          >
            <div v-if="form.pixel_organization_id">
              <pre>{{ form.pixel_organization_id }}</pre>
              <el-button
                v-clipboard:copy="form.pixel_organization_id"
                v-clipboard:success="() => $message({ message: 'ID copied to clipboard.', type: 'success' })"
                icon="fa fa-lg fa-clipboard-list"
                plain
                class="pixel-id-copy"
              />
            </div>
            <div v-else>
              <el-button
                v-if="isInternal && !newOrg"
                :loading="orgPixelPending"
                @click="generatePixelOrgID"
              >
                Generate ID
              </el-button>
              <span v-else>
                N/A
              </span>
            </div>
          </el-form-item> -->

          <el-form-item
            v-if="isInternal"
            class="org-detail internal"
            label="Client Accessible S3 Uri"
          >
            <el-input
              v-model="form.client_accessible_uri"
              placeholder="s3://osd-client-prod/client-accessible"
            />
            <p class="help">
              <strong>Note:</strong> This must be a valid s3 URI the backend has write access to, specified in the format: <br>
              <strong><em>s3://[bucket]/[optional-path]</em></strong><br>
              omit trialing slashes.
            </p>
          </el-form-item>

          <el-form-item
            v-if="isInternal"
            class="org-detail internal"
            label="IP to MAID S3 Scan"
          >
            <el-checkbox v-model="form.ip2maid_scan" />
          </el-form-item>

          <el-form-item
            v-if="isInternal"
            class="org-detail internal"
            label="IP to MAID Hash"
          >
            <el-select v-model="form.ipmaid_hash_algorithm">
              <el-option
                v-for="hash in hashAlgorithms"
                :key="hash"
                :label="hash"
                :value="hash"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            class="override"
            label="Data modeling"
          >
            <el-input-number
              v-model="form.data_multiplier"
              :min="0"
              :precision="1"
              :step="0.5"
              type="number"
            /> %
          </el-form-item>
          <el-form-item
            class="override"
            label="Enhanced Attribution Report Banner"
          >
            <uploader
              ref="uploader"
              :accept-file-exts="fileExts"
              api-endpoint="/api/v1/organization/banner"
              single-file
              upload-field-name="organization-banner"
              style="width: 340px;"
              @complete="uploadComplete"
              @file-change="handleFileChange"
              @upload-state-change="handleStateChange"
            />
          </el-form-item>

          <el-form-item
            v-if="isInternal"
            class="org-detail internal"
            label="Pixel Partners"
          >
            <el-select
              v-model="form.pixel_partners"
              multiple
            >
              <el-option
                v-for="partner in pixelPartners"
                :key="partner"
                :label="partner"
                :value="partner"
              />
            </el-select>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane
          v-if="!newOrg"
          label="Edit Tags"
        >
          <tag-manager />
        </el-tab-pane>

        <el-tab-pane label="Publishing Credentials">
          <publish-credentials
            :publishing.sync="form.publishing"
            @save="handleSave"
          />
        </el-tab-pane>

        <el-tab-pane
          v-if="isInternal"
          label="Privileges"
        >
          <el-form-item
            class="override internal"
            label="Historical Data Access"
          >
            <el-checkbox v-model="form.historical_access" />
          </el-form-item>

          <el-form-item
            v-if="form.heatmap_types"
            class="override internal"
            label="Heatmap Types"
          >
            <el-checkbox-group v-model="form.heatmap_types">
              <el-checkbox
                label="Households"
                disabled
              />
              <el-checkbox label="Workplaces" />
              <el-checkbox label="Observations" />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            class="override internal"
            label="Omit Report Branding"
          >
            <el-checkbox v-model="form.omit_branding">
              <small>Omit Branding on Audience &amp; Attribution Reports</small>
            </el-checkbox>
          </el-form-item>
          <el-form-item
            class="override internal"
            label="Omit Changelog"
          >
            <el-checkbox v-model="form.omit_changelog">
              <small>Hide changelog from header</small>
            </el-checkbox>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="Override System Defaults">
          <el-form-item
            v-if="isInternal"
            class="override internal"
            label="OnSpot API Key"
            prop="api_key"
          >
            <el-input v-model="form.api_key" />
          </el-form-item>
          <el-form-item
            v-if="isInternal"
            class="override internal"
            label="RTBiQ CPM"
          >
            <el-input-number
              v-model="form.rtbiq_cpm"
              :min="0"
              :precision="2"
              :step="0.01"
              type="number"
            />
          </el-form-item>

          <el-form-item
            v-if="canSetHash"
            class="override"
            label="Hash Algorithm"
          >
            <el-select v-model="form.preferred_hash_algorithm">
              <el-option
                v-for="hash in hashAlgorithms"
                :key="hash"
                :label="hash"
                :value="hash"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            class="override"
            label="Publication Prefix"
          >
            <el-input v-model="form.publication_prefix">
              <template slot="append">
                _
              </template>
            </el-input>
            <span class="help">Automatically followed by an underscore</span>
          </el-form-item>
          <el-form-item
            class="override"
            label="Publication Suffix"
          >
            <el-input v-model="form.publication_suffix">
              <template slot="prepend">
                _
              </template>
            </el-input>
            <span class="help">Automatically preceded by an underscore<br><strong>[organization]</strong> will be replaced with organization name</span>
          </el-form-item>

          <el-form-item
            v-if="isInternal"
            class="override internal"
            label="Xandr Publishing Tokens"
          >
            <el-input
              v-model="xandr_tokens.tokens"
              type="textarea"
              :rows="4"
            />
          </el-form-item>
          <el-form-item
            v-if="isInternal"
            class="override internal"
            label="Xandr Publishing Separators"
          >
            <el-input
              v-model="xandr_tokens.separators"
              type="textarea"
              :rows="4"
            />
          </el-form-item>
          <el-form-item
            v-if="isInternal"
            class="override internal"
          >
            <el-checkbox v-model="form.use_s2_level">
              Use S3-backed API instead of Cassandra
            </el-checkbox>
          </el-form-item>
          <el-form-item
            v-if="isInternal"
            class="override internal"
            label="OOH Measurement Visit Span"
          >
            <el-input
              v-model="form.visitSpan"
              type="number"
            />
          </el-form-item>
          <el-form-item
            v-if="isInternal"
            class="override internal"
            label="OOH Measurement Visit Multiplier"
          >
            <el-input
              v-model="form.visitMultiplier"
              type="number"
            />
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane
          v-if="isInternal && !newOrg"
          label="Billing Matrix"
        >
          <billing-matrix :billing-rates="billingRates" />
        </el-tab-pane>
        <el-tab-pane label="Webhooks">
          <el-form-item
            label="Slack Webhook"
            prop="slack_webhook"
          >
            <el-input v-model="form.slack_webhook" />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>

      <div class="buttons">
        <el-button
          type="text"
          @click="handleReset"
        >
          Reset
        </el-button>
        <el-button @click="handleCancel">
          Cancel
        </el-button>
        <el-button
          :loading="busy"
          type="primary"
          @click="handleSave"
        >
          Save
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import { hashAlgorithms, pixelPartners, preferredHashAlgorithm, rtbiqCpmDefault } from '@/constants';
import { organization as organizationApi, billing } from '@/adonis-api';
import OrgPicker from '../../global/OrgPicker.vue';
import PublishCredentials from '../../admin/PublishCredentials.vue';
import TagManager from '../../global/TagManager.vue';
import BillingMatrix from '../../admin/BillingMatrix';
import _get from 'lodash/get';
import validator from 'validator';
import Uploader from '@/components/global/Uploader.vue';

export default {
  name: 'PageEditOrganization',
  components: { OrgPicker, PublishCredentials, TagManager, BillingMatrix, Uploader },

  data() {
    return {
      fileExts: ['.jpg', '.png', '.svg'],
      banner_url: '',
      banner: null,
      fileList: [],
      bannerLoading: false,
      loading: false,
      busy: false,
      hashAlgorithms: hashAlgorithms, // Defined in constants file.
      form: {},
      xandr_tokens: {
        tokens: '',
        separators: '',
      },
      newOrg: false,
      org: {},
      billingRates: [],
      rules: Object.freeze({
        name: [
          {
            required: true,
            message: 'Organization name is required.',
            trigger: 'blur',
          },
        ],
        api_key: [
          {
            validator(rule, value, callback) {
              if (value === null || validator.isAlphanumeric(value) || validator.isEmpty(value)) callback();
              else {
                callback(new Error('Invalid Api Key.'));
              }
            },
            trigger: 'blur',
          },
        ],
        slack_webhook: [
          {
            validator(rule, value, callback) {
              if (value === null || validator.isURL(value) || validator.isEmpty(value)) callback();
              else {
                callback(new Error('Invalid URL.'));
              }
            },
            trigger: 'blur',
          },
        ],
      }),
      orgPixelPending: false,
      pixelOrgJobId: null,
    };
  },

  computed: {
    ...mapGetters('user', ['orgInfo', 'isInternal', 'hasPermission']),

    canSetHash() {
      return this.isInternal || this.hasPermission('configure_hash_algorithm');
    },
    jobInfo() {
      return this.$store.getters['mqtt/getJob'](this.pixelOrgJobId);
    },

    pixelPartners() {
      return pixelPartners;
    },

  },

  watch: {
    jobInfo: {
      handler(jobInfo) {
        if (
          _get(jobInfo, 'payload.result') &&
          _get(jobInfo, 'payload.status') === 'COMPLETE'
        ) {
          this.orgPixelPending = false;
          this.org.pixel_organization_id = jobInfo.payload.result.org.pixel_organization_id;
        } else if (
          _get(jobInfo, 'payload.status') === 'FAILED'
        ) {
          this.$alert(_get(jobInfo, 'payload.errors'));
          this.orgPixelPending = false;
        }
      },
      deep: true,
    },
  },

  async created() {
    try {
      const { id } = this.$route.params;

      if (!id) {
        // Adding a new org
        this.newOrg = true;
        this.org = Object.freeze({
          parent_organization: this.orgInfo,
          publishing: [],
          api_key: '',
          disabled: false,
          data_multiplier: 0,
          client_accessible_uri: null,
          attribution_overrides: false,
          custom_attribution_reports: false,
          historical_access: false,
          omit_branding: false,
          ipmaid_hash_algorithm: preferredHashAlgorithm, // Defined in constants file.
          preferred_hash_algorithm: preferredHashAlgorithm, // Defined in constants file.
          name: '',
          rtbiq_cpm: this.orgInfo.name === 'RTBiQ' ? rtbiqCpmDefault : 0, // Defined in constants file.
          heatmap_types: ['Households'],
          xandr_tokens: null,
          use_s2_level: false,
          slack_webhook: '',
          ip2maid_scan: false,
          omit_changelog: false,
          visitSpan: 15,
          visitMultiplier: 3.5,
          pixel_partners: pixelPartners,
        });
      } else {
        this.loading = true;
        this.org = {
          heatmap_types: [],
          ...Object.freeze(
            await organizationApi.findOne(id),
          ),
        };
        this.billingRates = await billing.getBillingRates(id);
        this.loading = false;
      }

      this.populateForm();
    } catch (e) {
      this.$reportError(e);
    }
  },

  methods: {
    handleFileChange(fileList) {
      this.banner = fileList[0];
    },
    uploadComplete(result) {
      this.banner_url = result.data.banner_url;
    },
    handleStateChange(isUploading) {
      this.bannerLoading = isUploading;
    },
    async generatePixelOrgID() {
      this.orgPixelPending = true;
      const job = await organizationApi.generatePixelOrgId(this.org.id);
      this.$store.dispatch('mqtt/addRawJob', job);
      this.pixelOrgJobId = job.id;
    },
    goBack() {
      this.$router.push('/admin/organizations');
    },

    handleCancel() {
      this.goBack();
    },

    handleReset() {
      this.populateForm();
    },

    async handleSave() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        return;
      }

      await this.$refs.uploader.startUpload({
        organization_id: this.org.id,
      });

      const hasXandrTokens = this.xandr_tokens.tokens.split('\n').filter(i => i).length > 0;

      this.busy = true;

      let rtbiqCpm = 0;

      if (this.form.parent_organization && this.form.parent_organization.name === 'RTBiQ') {
        if (this.form.rtbiq_cpm !== rtbiqCpmDefault && this.form.rtbiq_cpm !== 0) {
          rtbiqCpm = this.form.rtbiq_cpm;
        }
        rtbiqCpm = rtbiqCpmDefault;
      }

      if (!this.newOrg) {
        const params = {
          ..._omit(this.form, ['pixel_organization_id']),
          billing: this.billingRates,
          rtbiqCpm,
          xandr_tokens: hasXandrTokens ? {
            tokens: this.xandr_tokens.tokens.split('\n'),
            separators: this.xandr_tokens.separators.split('\n'),
          } : null,
        };

        if (this.form.parent_organization) {
          params.parent_id = this.form.parent_organization.id;
        }

        await organizationApi.update(this.org.id, params);
      } else {
        await organizationApi.create({
          ..._omit(this.form, ['pixel_organization_id']),
          parent_id: this.form.parent_organization.id,
          rtbiqCpm,
          xandr_tokens: hasXandrTokens ? {
            tokens: this.xandr_tokens.tokens.split('\n'),
            separators: this.xandr_tokens.separators.split('\n'),
          } : null,
        });
        window.mixpanel.track('Create Organization', {
          Name: this.form.name,
          'Parent Organization': this.form.parent_organization.name,
          'Historical Access': this.form.historical_access,
        });
      }

      this.busy = false;
      this.goBack();
    },

    populateForm() {
      this.form = _pick(this.org, [
        'api_key',
        'attribution_overrides',
        'client_accessible_uri',
        'custom_attribution_reports',
        'disabled',
        'historical_access',
        'name',
        'omit_branding',
        'omit_changelog',
        'parent_organization',
        'publishing',
        'rtbiq_cpm',
        'pixel_organization_id',
        'pixel_partners',
        'ip2maid_scan',
        'ipmaid_hash_algorithm',
        'preferred_hash_algorithm',
        'publication_prefix',
        'publication_suffix',
        'heatmap_types',
        'use_s2_level',
        'slack_webhook',
        'data_multiplier',
        'visitSpan',
        'visitMultiplier',
      ]);

      if (this.org.xandr_tokens) {
        this.xandr_tokens = {
          tokens: this.org.xandr_tokens.tokens.join('\n'),
          separators: this.org.xandr_tokens.separators.join('\n'),
        };
      } else {
        this.xandr_tokens = {
          tokens: '',
          separators: '',
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.org-detail {
  width: 500px;
}

.override {
  width: 900px;
}

.internal {
  background: rgba(255, 100, 100, 0.15)
}

.buttons {
  margin-top: 1em;
  text-align: right;
}

.help {
  font-size: .8em;
  line-height: 1.2em;
}

pre {
  margin: 0 1em 0 0;
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}

.pixel-id-copy {
  position: absolute;
  top: 0;
  right: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
