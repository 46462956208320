<template>
  <div class="reset">
    <el-button
      type="primary"
      @click="handleClick"
    >
      Reset Local Storage
    </el-button>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      localStorage.clear();
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.reset {
  text-align: center;
}
</style>
