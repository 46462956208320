<template>
  <div>
    <h3>Administer Users</h3>
    <user-library-table />
  </div>
</template>

<script>
import UserLibraryTable from '../../admin/UserLibraryTable';

export default {
  components: {
    UserLibraryTable,
  },
};
</script>
