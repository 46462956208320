<template>
  <div>
    <el-form
      ref="whitelabelForm"
      class="form"
      label-position="top"
      :inline="true"
      :model="form"
      :rules="rules"
    >
      <div>
        <el-form-item
          prop="organization"
          label="Organization"
        >
          <org-picker :organization.sync="form.organization" />
        </el-form-item>
        <el-form-item
          prop="domain"
          label="Domain"
        >
          <el-input v-model="form.domain" />
        </el-form-item>
        <el-form-item
          prop="logo"
          label="Logo"
        >
          <uploader
            ref="uploader"
            :accept-file-exts="['.png','.gif','.svg','.jpg']"
            api-endpoint="/api/v1/whitelabels"
            single-file
            upload-field-name="logo"
            @complete="handleUploadComplete"
            @file-change="handleFileChange"
            @upload-state-change="handleStateChange"
          >
            Drag image file here
          </uploader>
        </el-form-item>
      </div>
      <div>
        <el-checkbox v-model="form.intercom">
          Enable Intercom in-app support
        </el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="form.colorless">
          Remove color from header background
        </el-checkbox>
      </div>
    </el-form>
    <el-button
      :loading="busy"
      type="info"
      :disabled="!formReady"
      @click="saveWhitelabel"
    >
      Save
    </el-button>
  </div>
</template>

<script>
import validator from 'validator';
import _get from 'lodash/get';
import OrgPicker from '../../../global/OrgPicker.vue';
import Uploader from '../../../global/Uploader.vue';

export default {
  components: {
    OrgPicker,
    Uploader,
  },
  props: {
    whiteLabel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      busy: false,
      form: {
        organization: {},
        domain: '',
        logo: null,
        intercom: false,
        colorless: false,
      },
      rules: Object.freeze({
        domain: [
          {
            validator(rule, value, callback) {
              if (validator.isFQDN(value)) callback();
              else {
                callback(new Error('Invalid domain name.'));
              }
            },
            trigger: 'blur',
          },
        ],
      }),
    };
  },
  computed: {
    formReady() {
      return this.form.organization.id && this.form.domain && this.form.logo;
    },
  },
  watch: {
    whiteLabel(value) {
      if (value.organization.id) {
        this.form.organization = value.organization;
        this.form.domain = value.domain;
        this.form.logo = value.logo;
        this.form.intercom = value.intercom;
        this.form.colorless = value.colorless;
      }
    },
  },
  mounted() {
    if (_get(this.whiteLabel, 'organization.id')) {
      this.form.organization = this.whiteLabel.organization;
      this.form.domain = this.whiteLabel.domain;
      this.form.logo = this.whiteLabel.logo;
      this.form.intercom = this.whiteLabel.intercom;
      this.form.colorless = this.whiteLabel.colorless;
    }
  },
  methods: {
    handleFileChange(fileList) {
      this.form.logo = fileList[0];
    },
    handleStateChange(busy) {
      this.busy = busy;
    },
    handleUploadComplete(data) {
      if (data.success) {
        this.$store.dispatch('settings/whitelabel/addWhitelabel', {
          // intercom: data.whitelabel.intercom,
          logo: data.data.whitelabel.logo,
          domain: data.data.whitelabel.domain,
        });

        this.$emit('update');
        this.$refs.uploader.reset();
      }
    },
    async saveWhitelabel() {
      try {
        await this.$refs.whitelabelForm.validate();
      } catch (e) {
        return;
      }

      try {
        await this.$refs.uploader.startUpload({
          colorless: this.form.colorless,
          intercom: this.form.intercom,
          organization_id: this.form.organization.id,
          domain: this.form.domain,
        });
        this.form.organization = {};
        this.form.domain = '';
        this.form.logo = null;
        this.form.intercom = false;
        this.form.colorless = false;
      } catch (e) {
        this.$reportError(e);
      }

      this.$emit('saved-whitelabel');
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-bottom: 20px;
}

.avatar-uploader .el-upload {
  width: 100px;
  height: 80px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    line-height: 60px;
    text-align: center;
  }
  p {
    margin-top: -20px;
    color: #ccc;
  }
  .logo {
    width: 100px;
    height: 80px;
    display: block;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #20a0ff;
}
</style>
