<template>
  <div class="tag-manager">
    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="tag"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :find-options="{ organizationId: $route.params.id }"
      @action="handleAction"
    >
      <div slot="additional-controls">
        <el-button
          type="primary"
          @click="handleAddTag"
        >
          Add Tag
        </el-button>
      </div>
      <el-table-column
        prop="name"
        label="Name"
        sortable="custom"
      />
      <el-table-column
        :width="110"
        prop="created_at"
        label="Created"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.created_at | dateString }}
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { tag as tagApi } from '@/adonis-api';
import DataTable from './DataTable.vue';
import { regexPatterns } from '@/constants';

export default {
  name: 'TagManager',
  components: { DataTable },

  data() {
    return {
      dataTable: Object.freeze({
        actions: [
          {
            command: 'rename',
            icon: 'edit',
            label: 'Rename',
          },
          {
            command: 'delete',
            icon: 'trash',
            label: 'Delete',
            type: 'danger',
          },
        ],
        order: 'ascending',
        sortBy: 'name',
      }),
      tag: Object.freeze(tagApi),
    };
  },

  methods: {
    async handleAction(command, tag) {
      switch (command) {
        case 'delete':
          try {
            await this.$confirm(
              'Are you sure you want to delete the tag' +
                `${tag.name}? This cannot be undone.`,
              {
                title: 'Delete tag?',
                type: 'warning',
              },
            );

            await tagApi.delete(tag.id);
            this.$refs.dataTable.invokeQuery();
          } catch (e) {
            if (e !== 'cancel') this.$reportError(e);
          }
          break;
        case 'rename':
          try {
            const { value } = await this.$prompt(
              'Enter the new tag name:',
              'Rename Tag',
              {
                inputErrorMessage: 'Name must 2-32 characters and consist of ' +
                  'letters, numbers, spaces, and hyphens.',
                inputPattern: regexPatterns.tagName,
                inputValue: tag.name,
              },
            );

            await tagApi.update(tag.id, {
              name: value,
            });
            this.$refs.dataTable.invokeQuery();
          } catch (e) {
            if (e !== 'cancel') this.$reportError(e);
          }
          break;
        default:
          break;
      }
    },

    async handleAddTag() {
      try {
        const { value } = await this.$prompt(
          'Enter the new tag name:',
          'Add Tag',
          {
            inputErrorMessage: 'Name must 2-32 characters and consist of ' +
                  'letters, numbers, spaces, and hyphens.',
            inputPattern: regexPatterns.tagName,
          },
        );

        await tagApi.create({
          name: value,
          organizationId: this.$route.params.id,
        });
        this.$refs.dataTable.invokeQuery();
      } catch (e) {
        if (e !== 'cancel') this.$reportError(e);
      }
    },
  },
};
</script>

<style scoped>
.tag-manager {
  max-width: 940px;
}
</style>
