<template>
  <div>
    <el-tabs>
      <el-tab-pane
        v-for="(tab, index) of tabs"
        :key="index"
        :label="tab.label"
      >
        <el-checkbox-group v-model="chosenPermissions">
          <div
            v-for="item in perms(tab.segment)"
            :key="item.id"
          >
            <el-checkbox
              :label="item.id"
              :class="{ internal: item.internal_only }"
            >
              {{ item.name }}
              <span class="help-text">
                -- {{ item.description }}
                {{
                  item.internal_only ? '(Grantable by internal users only)' : ''
                }}
              </span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-tab-pane>
    </el-tabs>
    <el-button
      v-if="chosenPermissions.length > 0"
      @click="resetPermissions"
    >
      Reset
    </el-button>
  </div>
</template>

<script>
import { permission } from '@/adonis-api';

export default {
  props: {
    permissions: {
      type: Array,
      default: () => [],
    },
    userId: { // Only used to tell when we're editing a different user
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      chosenPermissions: [],
      permissionList: [],
    };
  },
  computed: {
    showApiTab() {
      return this.permissionList.map(i => i.segment).includes('API');
    },

    tabs() {
      const sections = [
        { label: 'Geoframe', segment: 'GEOFRAME' },
        { label: 'Audience', segment: 'AUDIENCE' },
        { label: 'Attribution', segment: 'ATTRIBUTION' },
        { label: 'Data Sources', segment: 'DATASOURCE' },
        { label: 'Admin', segment: 'ADMIN' },
        { label: 'Misc', segment: 'MISC' },
        { label: 'API', segment: 'API' },
      ];

      return sections.filter(section =>
        this.permissionList.map(i => i.segment).includes(section.segment),
      );
    },
  },

  watch: {
    chosenPermissions() {
      this.emitUpdate();
    },

    userId: {
      handler(perms) {
        this.chosenPermissions = this.permissions;
      },
      immediate: true,
    },
  },

  async created() {
    const result = await permission.find();
    this.permissionList = result.data;
  },

  methods: {
    emitUpdate() {
      this.$emit(
        'update:permissions',
        [...this.chosenPermissions].filter(
          p => p,
        ),
      );
    },

    perms(segment) {
      return this.permissionList.filter(i => i.segment === segment);
    },

    resetPermissions() {
      this.chosenPermissions = [];
    },
  },
};
</script>

<style lang="scss">
.internal {
  &.is-checked span.el-checkbox__label {
    color: #c04 !important;
  }
  span.el-checkbox__label {
    color: #a00 !important;
  }
    &.is-checked span.el-radio__label {
    color: #c04 !important;
  }
  span.el-radio__label {
    color: #a00 !important;
  }
}

.permissions .el-tab-pane {
  height: 25em;
  overflow: auto;
}

.el-checkbox {
  margin-bottom: 6px;
}

.el-checkbox__label {
  position: relative;
  top: 2px;
}

.help-text {
  font-size: 0.8em;
}
</style>
