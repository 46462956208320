<template>
  <div>
    <div>
      <el-button
        v-if="hasPermission('create_organizations')"
        type="info"
        @click="handleStartAdd"
      >
        <i class="fa fa-plus" /> Add New Organization
      </el-button>
    </div>

    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="organization"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      :row-class-name="tableRowClassName"
      disable-checkboxes
      @action="handleAction"
      @archive="value => showingArchive = value"
    >
      <el-table-column type="expand">
        <template slot-scope="data">
          <organization-children :org="data.row" />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name"
        sortable="custom"
      />
      <el-table-column
        prop="parent_organization"
        label="Parent"
        sortable="custom"
      >
        <template slot-scope="data">
          <span v-if="data.row.parent_organization">
            {{ data.row.parent_organization.name }}
          </span>
          <span v-else>
            N/A
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!showingArchive"
        :width="110"
        prop="created_at"
        label="Created"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        v-else
        :width="110"
        prop="deleted_at"
        label="Archived"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.deleted_at | dateString }}
        </template>
      </el-table-column>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { organization } from '@/adonis-api';
import DataTable from '../global/DataTable.vue';
import OrganizationChildren from './OrganizationChildren.vue';
import { deleteOrganization } from '@/methods/organizations';

export default {
  components: {
    DataTable,
    OrganizationChildren,
  },

  data() {
    return {
      dataTable: {
        actions: [
          {
            command: 'edit',
            icon: 'edit',
            label: 'Edit',
          },
        ],
        order: 'ascending',
        sortBy: 'name',
      },
      editingOrg: {},
      org: {},
      showingArchive: false,
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission']),

    canAddOrg() {
      return this.hasPermission('create_organizations');
    },
  },

  created() {
    this.organization = organization;
  },

  methods: {
    dismissDialog() {
      this.org = {};
      this.showDialog = false;
    },

    async handleAction(command, org) {
      switch (command) {
        case 'delete':
          this.$confirm(
            `Are you sure you want to delete the organization "${
              org.organizationName
            }"` + ' This operation cannot be undone.',
            'Delete Organization',
          )
            .then(() => {
              return deleteOrganization({ id: org._id });
            })
            .then(() => {
              this.$message('Organization deleted.');
            })
            .catch(err => {
              if (err !== 'cancel') {
                this.$reportError(err);
              }
            });
          break;
        case 'edit':
          this.$router.push(`/admin/organizations/${org.id}/edit`);
          break;
        default:
          break;
      }
    },

    handleStartAdd() {
      this.$router.push('/admin/organizations/new');
    },

    tableRowClassName({ row }) {
      return row.disabled ? 'disabled' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.pagination {
  display: inline-block;
  margin: 16px 0;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin: 4px;
  background: #dedede;
  color: #666;
}
.cell .el-tag:first-child {
  margin-top: 4px;
}

.search-box {
  max-width: 180px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

:deep(.el-table .disabled),
:deep(.el-table tr.el-table__row--striped.disabled td) {
  background-color: mix($--color-white, $--color-danger, 90%);
}
</style>
