<template>
  <div>
    <h2>Create Changelog Entries</h2>
    <div
      v-for="(change, index) of changes"
      :key="index"
      class="change"
    >
      <el-radio-group v-model="change.type">
        <el-radio-button label="NEW" />
        <el-radio-button label="IMPROVEMENT" />
        <el-radio-button label="UPDATE" />
        <el-radio-button label="FIX" />
      </el-radio-group>
      <el-input
        v-model="change.title"
        placeholder="Changelog Title"
        :maxlength="255"
      />
      <wysiwyg
        v-model="change.description"
        class="change-editor"
      />
      <el-button @click="remove(index)">
        <i class="far fa-trash" /> Remove
      </el-button>
    </div>
    <el-button
      type="primary"
      @click="addChange"
    >
      <i class="far fa-plus" /> Add Another Change
    </el-button>
    <el-button
      type="success"
      :disabled="notReady"
      @click="save"
    >
      Publish Changes
    </el-button>
  </div>
</template>

<script>
import { changes } from '@/adonis-api';

export default {
  data() {
    return {
      changes: [],
    };
  },

  computed: {
    notReady() {
      return this.changes.length < 1 || this.changes.some(o => !o.title || !o.description);
    },
  },

  mounted() {
    this.addChange();
  },

  methods: {
    addChange() {
      const emptyChange = {
        title: '',
        type: 'NEW',
        description: '',
      };

      this.changes.push(Object.assign(emptyChange));
    },
    remove(index) {
      this.changes.splice(index, 1);
    },
    async save() {
      try {
        await this.$confirm(
          `Are you sure you want to publish ${
            this.changes.length
          } change${this.changes.length === 1 ? '' : 's'}?`,
          'Publish Changes',
          {
            type: 'info',
          },
        );

        // Publish changes
        await changes.saveChanges({ changes: this.changes });

        this.$message('Changes published');
        this.changes = [];
        this.addChange();
      } catch (e) {
        if (e !== 'cancel') {
          this.$reportError(e);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .change {
    max-width: 1280px;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px dotted #efefef;
    .change-editor {
      margin: .5em 0;
    }
    .el-input {
      margin: .5em 0 .5em 12px;
      width: calc(100% - 392px);
      top: 1px;
      right: -3px;
    }
  }
</style>
