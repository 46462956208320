<template>
  <div v-if="children.length > 0">
    <h4>Child Organizations:</h4>
    <el-tag
      v-for="(item, index) of children"
      :key="index"
    >
      {{ item.name }}
    </el-tag>
  </div>
</template>

<script>
import { organization } from '@/adonis-api';

export default {
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      children: [],
    };
  },
  async mounted() {
    const result = await organization.family(this.org.id);
    this.children = result.descendants;
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 14px;
  text-decoration: underline;
  margin: 0 0 5px;
}
h4 {
  font-size: 11px;
  text-decoration: underline;
  margin: 0 0 5px;
}
.el-tag {
  font-size: 11px;
  padding: 3px;
  line-height: 12px;
  height: 20px;
  margin: 0 5px 5px 0;
}
</style>
