<template>
  <div>
    <h3>Settings</h3>
    <el-collapse v-model="activeSegment">
      <el-collapse-item
        title="Social Extension"
        name="socialExtension"
      >
        <div class="form-control">
          <label>Levels:</label>
          <el-input-number v-model="settingsForm.levels" />
        </div>

        <div class="form-control">
          <label>Count Min:</label>
          <el-input-number v-model="settingsForm.countMin" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Date Range"
        name="dateRange"
      >
        <div class="form-control">
          <label>End Days Ago:</label>
          <el-input-number
            v-model="settingsForm.rangeEnd"
            :min="0"
            :max="30"
          />
        </div>
        <p>
          These settings control the default allowed date range system-wide. The
          default can be overridden on a per-organization basis by
          <router-link to="/admin/organizations">
            editing the organization
          </router-link>.
        </p>
      </el-collapse-item>

      <el-collapse-item
        title="Geoframes"
        name="geoframes"
      >
        <div class="form-control">
          <label>Maximum Polygon Area:</label>
          <el-input-number
            v-model="settingsForm.maximumArea"
            :min="0"
            :max="5000000"
          />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Audience"
        name="audience"
      >
        <div class="form-control wide-label">
          <label>Trade Area Geoframe Limit:</label>
          <el-input-number v-model="settingsForm.tradeAreaGeoframeLimit" />
        </div>
        <div class="form-control wide-label">
          <label>Predicted Movers Zipcode Limit:</label>
          <el-input-number v-model="settingsForm.predictedMoversZipcodeLimit" />
        </div>
        <div class="form-control wide-label">
          <label>Journey Report Geoframe Limit:</label>
          <el-input-number
            v-model="settingsForm.journeyGeoframeLimit"
            :min="2"
          />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Attribution"
        name="attribution"
      >
        <div class="form-control wide-label">
          <label>DSP Devices Matched (Percentage Estimate):</label>
          <el-slider
            v-model="settingsForm.dspMatchPercent"
            show-input
          />
        </div>
        <div class="form-control wide-label">
          <label>DSP Devices Served (Percentage Estimate):</label>
          <el-slider
            v-model="settingsForm.uniqueServedPercent"
            show-input
          />
        </div>
        <div class="form-control">
          <label>Destination Device Limit:</label>
          <el-input-number v-model="settingsForm.destinationDeviceLimit" />
        </div>
        <div class="form-control">
          <label>Destination Geoframe Limit:</label>
          <el-input-number v-model="settingsForm.destinationGeoframeLimit" />
        </div>
        <div class="form-control">
          <label>Target Device Limit:</label>
          <el-input-number v-model="settingsForm.targetDeviceLimit" />
        </div>
        <div class="form-control">
          <label>Target Geoframe Limit:</label>
          <el-input-number v-model="settingsForm.targetGeoframeLimit" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Autopolygon"
        name="autopolygon"
      >
        <div class="form-control">
          <label>Template uri:</label>
          <el-input v-model="settingsForm.autopolygonTemplateUri" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Census Tract"
        name="censesTract"
      >
        <div class="form-control">
          <label>Template uri:</label>
          <el-input v-model="settingsForm.censusTractTemplateUri" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Lat/Long Import"
        name="latlng_import"
      >
        <div class="form-control">
          <label>Template uri:</label>
          <el-input v-model="settingsForm.latLngTemplateUri" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="POI"
        name="poi"
      >
        <div class="form-control">
          <label>POI Index:</label>
          <el-input v-model="settingsForm.poiIndex" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Orders"
        name="orders"
      >
        <div class="form-control">
          <label>Template uri:</label>
          <el-input v-model="settingsForm.templateUri" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Reverse Appends"
        name="reverseAppends"
      >
        <div class="form-control">
          <label>Template uri:</label>
          <el-input v-model="settingsForm.reverseAppendsTemplateUri" />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="API Pressure Gauge"
        name="apiPressure"
      >
        <div class="form-control">
          <el-slider
            v-model="settingsForm.pressureRange"
            range
            :step="10"
            :max="15000"
          />
        </div>
        <div>
          <el-tag type="success">
            &lt; {{ options['apiPressure:range'][0] }}
          </el-tag>
          <el-tag type="warning">
            {{ options['apiPressure:range'][0] }} -
            {{ options['apiPressure:range'][1] }}
          </el-tag>
          <el-tag type="danger">
            &gt; {{ options['apiPressure:range'][1] }}
          </el-tag>
        </div>
        <div class="control-buttons">
          <el-button @click="settingsForm.pressureRange = [2500, 8000]">
            Reset to Recommended Defaults
          </el-button>
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Terms of Use"
        name="terms"
      >
        <div>
          <wysiwyg
            v-model="settingsForm.eula"
            class="terms-editor"
          />
        </div>
      </el-collapse-item>

      <el-collapse-item
        title="Maintenance"
        name="maintenance"
      >
        <p>
          When enabled, application access will be blocked for non-internal
          users during the defined maintenance window, and a warning will
          display to all users for 24 hours preceding the window.
        </p>
        <div class="form-control">
          <label>Maintenance Mode:</label>
          <el-switch
            v-model="settingsForm.maintenance.enabled"
            active-text="Enabled"
            inactive-text="Disabled"
          />
        </div>
        <div class="form-control">
          <label>Maintenance Window:</label>
          <el-date-picker
            v-model="settingsForm.maintenance.window"
            type="datetimerange"
            format="MMM d yyyy h:mma"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
          />
          <div style="display: inline-block">
            &nbsp; (Your local time)
          </div>
        </div>

        <div class="form-control">
          <label>Maintenance Reason:</label>
          <el-input v-model="settingsForm.maintenance.reason" />
        </div>
      </el-collapse-item>
    </el-collapse>

    <div class="form-element">
      <p>
        <el-button
          :disabled="!changedSettings"
          type="primary"
          @click="saveSettings"
        >
          Save Settings
        </el-button>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { settings as settingsApi } from '@/adonis-api';
import { isEmpty } from '@/helpers';
import { numericSort } from '@/helpers/sorters';

export default {
  name: 'PageSettings',
  data() {
    return {
      activeSegment: [],
      modifiedSettings: {},
      settingsForm: {},
      settingsMap: Object.freeze({
        'settingsForm.rangeEnd': 'date:rangeEnd',
        'settingsForm.maximumArea': 'geoframes:maximumArea',
        'settingsForm.dspMatchPercent': 'attribution:dspMatchPercent',
        'settingsForm.uniqueServedPercent': 'attribution:uniqueServedPercent',
        'settingsForm.paginationSizes': 'pagination:pageSizes',
        'settingsForm.psSize': 'pagination:pageSize',
        'settingsForm.poiIndex': 'poi:poiIndex',
        'settingsForm.templateUri': 'orders:templateUri',
        'settingsForm.reverseAppendsTemplateUri': 'reverseAppends:reverseAppendsTemplateUri',
        'settingsForm.reverseAppendsThreshold': 'reverseAppends:matchThreshold',
        'settingsForm.userGuideUri': 'userguide:poiUri',
        'settingsForm.pressureRange': 'apiPressure:range',
        'settingsForm.eula': 'eula',
        'settingsForm.latLngTemplateUri': 'latLng:latLngTemplateUri',
        'settingsForm.autopolygonTemplateUri': 'autopolygon:autopolygonTemplateUri',
        'settingsForm.censusTractTemplateUri': 'censusTract:censusTractTemplateUri',
        'settingsForm.autopolygonMatchThreshold': 'autopolygon:matchThreshold',
        'settingsForm.destinationDeviceLimit': 'attribution:destinationDeviceLimit',
        'settingsForm.destinationGeoframeLimit': 'attribution:destinationGeoframeLimit',
        'settingsForm.targetDeviceLimit': 'attribution:targetDeviceLimit',
        'settingsForm.targetGeoframeLimit': 'attribution:targetGeoframeLimit',
        'settingsForm.maintenance': 'maintenance',
        'settingsForm.tradeAreaGeoframeLimit': 'audience:tradeAreaGeoframeLimit',
        'settingsForm.predictedMoversZipcodeLimit': 'audience:predictedMoversZipcodeLimit',
        'settingsForm.levels': 'socialExtension:levels',
        'settingsForm.countMin': 'socialExtension:countMin',
        'settingsForm.journeyGeoframeLimit': 'audience:journeyGeoframeLimit',
      }),
    };
  },

  computed: {
    ...mapState('settings', ['options']),

    changedSettings() {
      return !isEmpty(this.modifiedSettings);
    },

    returnTime() {
      return moment()
        .add(this.maintForm.startIn, 'minutes')
        .add(this.maintForm.duration, 'minutes')
        .format('LLL');
    },
  },

  created() {
    const settingsData = {};

    // Set initial form values
    for (const field in this.settingsMap) {
      settingsData[field.replace(/^settingsForm\./, '')] =
        this.options[this.settingsMap[field]];
    }
    this.settingsForm = settingsData;

    this.$nextTick(() => {
      // Handle any special cases
      // TODO: sort as soon as user changes this
      this.settingsForm.paginationSizes.sort(numericSort);

      // Set up watchers for fields
      for (const field in this.settingsMap) {
        this.$watch(
          field,
          (value) => {
            this.$set(this.modifiedSettings, this.settingsMap[field], value);
          },
          {
            // Deep is necessary to handle object values
            deep:
              typeof this.settingsForm[this.settingsMap[field]] === 'object',
          },
        );
      }
    });
  },

  methods: {
    async saveSettings() {
      try {
        if (this.modifiedSettings.eula) {
          this.modifiedSettings['eula:updated'] = new Date();
        }
        await settingsApi.updateBulk(this.modifiedSettings);
        this.modifiedSettings = {};

        this.$message({
          message: 'Settings have been saved.',
          type: 'success',
        });
      } catch (e) {
        this.$notify.error({
          message:
            'An error occurred while saving settings. This issue has been reported.',
        });
        this.$reportError(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.maintenance-form {
  max-width: 400px;
}

.form-control {
  margin-bottom: 1rem;

  label {
    width: 150px;
    text-align: right;
    margin-right: 1rem;
    display: inline-block;
  }

  &.wide-label {
    label {
      width: auto;
    }
  }

  .el-input,
  .el-input-number {
    width: 40%;
  }

  &.wysiwyg {
    label {
      vertical-align: top;
    }

    .editr {
      width: 75%;
      display: inline-block;
    }
  }
}

.text-field {
  max-width: 400px;
}

.el-slider {
  max-width: 50%;
  height: 22px;
  display: inline-block;
}

:deep( .el-slider__runway) {
  width: 500px;

  &.show-input {
    width: calc(500px - 150px);
    margin-right: 150px;
  }
}

.message-sample {
  background: #eee;
  border: 1px solid #ccc;
  margin-left: 2em;
  max-width: 600px;
  padding: 2px 16px;
}

.control-buttons {
  margin-top: 12px;
}

fieldset {
  border: 1px solid #ebeef5;
  padding: 2em;

  legend {
    padding: 0 1em;
    color: #777;
  }
}
</style>
