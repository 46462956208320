<template>
  <div>
    <h3>Administer Organizations</h3>
    <organization-library-table />
  </div>
</template>

<script>
import OrganizationLibraryTable from '../../admin/OrganizationLibraryTable';

export default {
  components: {
    OrganizationLibraryTable,
  },
};
</script>
