<template>
  <div>
    <div v-if="billingRates">
      <div
        v-for="rates in billingRates"
        :key="rates.id"
      >
        <el-row
          type="flex"
          align="middle"
        >
          <el-col :span="6">
            <span class="capitalize"><h2>{{ rates.billable_product.toLowerCase().replace(/_/gi, " ") }}</h2></span>
          </el-col>

          <el-col
            :span="6"
          >
            <el-switch
              v-model="rates.active"
              active-text="Active"
            />
          </el-col>
        </el-row>
        <!-- NOT DSP AREA -->
        <div v-if="!(rates.billable_product === 'PUBLISHES_BY_DSP')">
          <el-row :gutter="10">
            <el-col :span="3">
              <span class="input-label">Flat Monthly Cost</span>
              <el-input
                v-model="rates.flat_monthly_rate"
                type="number"
              />
            </el-col>
            <el-col :span="3">
              <span class="input-label">Reports Batch Amount</span>
              <el-input
                v-model="rates.per_reports"
                placeholder="40"
                type="number"
                :disabled="checkDisabled(rates)"
              />
            </el-col>
            <el-col :span="3">
              <span class="input-label">Items/Devices Batch Amount</span>
              <el-input
                v-model="rates.per_items"
                placeholder="1000"
                type="number"
                :disabled="checkDisabled(rates)"
              />
            </el-col>
            <el-col :span="3">
              <span class="input-label">Rate Before Maximum</span>
              <el-input
                v-model="rates.in_max_rate"
                type="number"
                :disabled="checkDisabled(rates)"
              />
            </el-col>
          </el-row>
          <br>
          <el-row :gutter="10">
            <el-col :span="3">
              <span class="input-label">Monthly Maximum</span>
              <el-input
                v-model="rates.monthly_max"
                type="number"
                :disabled="checkDisabled(rates)"
              />
            </el-col>
            <el-col :span="3">
              <span class="input-label">Monthly Minimum</span>
              <el-input
                v-model="rates.monthly_min"
                type="number"
                :disabled="checkDisabled(rates)"
              />
            </el-col>
            <el-col :span="3">
              <span class="input-label">Rate After Minimum</span>
              <el-input
                v-model="rates.out_min_rate"
                type="number"
                :disabled="checkDisabled(rates)"
              />
            </el-col>
            <el-col :span="3">
              <span class="input-label">Open Rate Cost</span>
              <el-input
                v-model="rates.open_rate"
                type="number"
                :disabled="checkDisabled(rates)"
              />
            </el-col>
          </el-row>
        </div>
        <!-- END NOT DSP AREA  -->
        <el-col :span="12">
          <div v-if="rates.billable_product === 'PUBLISHES_BY_DSP'">
            <el-tabs
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane
                v-for="dsp in rates.dsp_rates"
                :key="dsp.id"
                :label="dsp.dsp_name"
                :name="dsp.dsp_name"
              >
                <el-row :gutter="10">
                  <el-col :span="3">
                    <span class="input-label">Rate</span>
                    <el-input
                      v-model="dsp.dsp_rate"
                      type="number"
                      placeholder="0.0"
                      :disabled="dsp.dsp_monthly_rate > 0"
                    />
                  </el-col>
                  <el-col :span="3">
                    <span class="input-label">Per Publishes</span>
                    <el-input
                      v-model="dsp.per_publish"
                      type="number"
                      placeholder="1"
                      :disabled="dsp.dsp_monthly_rate > 0"
                    />
                  </el-col>
                  <el-col :span="5">
                    <span class="input-label">Unlimited Monthly Rate</span>
                    <el-input
                      v-model="dsp.dsp_monthly_rate"
                      type="number"
                      placeholder="0.0"
                    />
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    billingRates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeName: 'The TradeDesk',
    };
  },
  methods: {
    handleClick() {

    },
    checkDisabled(rates) {
      if (rates.flat_monthly_rate > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.capitalize {
  text-transform: capitalize;
}

.input-label {
    display: inline-block;
  }

</style>
