<template>
  <div>
    <el-tabs
      v-model="tabIdx"
      @tab-click="tabClicked()"
    >
      <el-tab-pane
        v-for="(tab, index) of tabs"
        :key="index"
        :label="tab.name"
      >
        <div class="flex-row">
          <el-input
            v-model="query"
            class="filter-box"
            :trigger-on-focus="false"
            clearable
            prefix-icon="fa fa-search"
            placeholder="Filter.."
            spellcheck="false"
            type="text"
            @input="resetPage()"
          />
          <el-pagination
            :current-page="pageNum"
            :page-size="pageSize"
            :total="totalItems"
            hide-on-single-page
            layout="prev, pager, jumper, next"
            @current-change="handlePageChange"
          />
        </div>
        <el-table
          :key="forceRender"
          :data="credentialSets"
          :row-class-name="rowClass"
        >
          <el-table-column
            prop="is_default"
            label="Default"
            width="70"
          >
            <template slot-scope="{ row }">
              <el-form-item class="no-label centered">
                <el-radio
                  v-model="defaults[tab.id]"
                  :label="row.credentials.name"
                  @change="updateDefault(row.credentials.name, tab.id)"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(field, idx) of tab.credential_fields"
            :key="`${index}-${idx}`"
            :prop="field.name"
            :label="field.label"
          >
            <template slot-scope="{ row }">
              {{ row.credentials[field.name] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="disabled"
            width="130"
            style="vertical-align:middle;"
          >
            <template slot-scope="{ row }">
              <el-switch
                v-model="row.disabled"
                active-text="Disabled"
                active-color="#ff4949"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="remove"
            width="150"
          >
            <template slot-scope="{ row }">
              <el-button @click="editRow(row)">
                <i class="fa fa-pencil" />
              </el-button>
              <el-button @click="row.remove = !row.remove; forceRender += 1">
                <i
                  v-if="row.remove"
                  class="fa fa-undo-alt"
                />
                <i
                  v-else
                  class="fa fa-trash-alt"
                />
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-button
          type="info"
          @click="addCredentialSet(tab)"
        >
          <i class="fa fa-plus" /> Add New Credential Set
        </el-button>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      v-if="newCredentialSet !== null"
      title="Add Credential Set"
      :visible.sync="credentialFormVisible"
      @close="newCredentialSet = null"
    >
      <el-form
        ref="credentialForm"
        :model="newCredentialSet.credentials"
        :rules="getRules()"
      >
        <el-form-item
          v-for="(field, idx) of credentialFields"
          :key="idx"
          :label="field.label"
          :required="true"
          :prop="field.name"
        >
          <el-input-number
            v-if="field.type === 'integer'"
            v-model="newCredentialSet.credentials[field.name]"
          />
          <el-input
            v-else
            v-model.trim="newCredentialSet.credentials[field.name]"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="credentialFormVisible = false">Cancel</el-button>
        <el-button
          v-if="newCredentialSet"
          type="primary"
          :disabled="fieldsComplete"
          @click="confirmNewCredential('credentialForm')"
        >Add</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="editableRow !== null"
      title="Edit Credential Set"
      :visible.sync="editCredentialFormVisible"
    >
      <el-form
        ref="editCredentialForm"
      >
        <el-form-item
          v-for="(field, idx) of credentialFields"
          :key="idx"
          :label="field.label"
        >
          <el-input-number
            v-if="field.type === 'integer'"
            v-model="editableRow.credentials[field.name]"
          />
          <el-input
            v-else
            v-model.trim="editableRow.credentials[field.name]"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="editFinish()"
        >Finish</el-button>
      </span>
    </el-dialog>
    <el-row :gutter="20">
      <el-col
        :span="4"
        :offset="9"
      >
        <el-alert
          v-if="unsaved"
          title="You have unsaved changes."
          type="warning"
          show-icon
          :closable="false"
          center
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { publishPartner } from '@/adonis-api';

export default {
  props: {
    publishing: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      defaults: [],
      publishingList: [],
      publishPartners: [],
      pageNum: 1,
      pageSize: 15,
      tabIdx: 0,
      query: '',
      forceRender: 0,
      jumpToLast: false,
      credentialFormVisible: false,
      editCredentialFormVisible: false,
      newCredentialSet: null,
      editableRow: null,
      unsaved: false,
    };
  },

  computed: {
    ...mapGetters('user', ['permissions']),
    tabs() {
      const allowedPublishers = this.permissions
        .filter(i => i.substr(0, 8) === 'publish_')
        .map(i => i.slice(8));

      return this.publishPartners.filter(
        pp =>
          allowedPublishers.includes(pp.machine_name) &&
          pp.credential_fields.length > 0,
      );
    },
    totalCredentials() {
      if (this.query.length !== 0) {
        return this.tabs !== null && this.tabs.length > 0
          ? this.publishingList
            .filter(i => i.partner_id === this.tabs[this.tabIdx].id)
            .filter(i => {
              return Object.values(i.credentials).some(this.filterContains);
            })
            .sort(this.publishSort())
          : [];
      }
      return this.tabs !== null && this.tabs.length > 0
        ? this.publishingList
          .filter(i => i.partner_id === this.tabs[this.tabIdx].id)
          .sort(this.publishSort())
        : [];
    },
    credentialSets() {
      return this.totalCredentials.slice(
        (this.pageNum - 1) * this.pageSize,
        this.pageNum * this.pageSize,
      );
    },
    credentialFields() {
      return this.tabs !== null && this.tabs.length > 0
        ? this.tabs[this.tabIdx].credential_fields
        : [];
    },
    totalItems() {
      return this.totalCredentials.length;
    },
    fieldsComplete() {
      const that = this;
      let complete = false;
      Object.keys(this.newCredentialSet.credentials).forEach(function(key) {
        if (that.newCredentialSet.credentials[key] == null) {
          complete = true;
        }
      });
      return complete;
    },
  },

  watch: {
    publishingList: {
      handler(value) {
        for (const item of value) {
          if (item.is_default) {
            this.defaults[item.partner_id] = item.credentials.name;
          }
        }
        this.$emit('update:publishing', value);
      },
      deep: true,
      immediate: true,
    },
    publishing: {
      handler(value) {
        this.publishingList = value;
      },
      immediate: true,
    },
  },

  async created() {
    const result = await publishPartner.find();
    result.data.forEach(pp => {
      Object.assign(pp, { remove: false });
    });
    this.publishPartners = result.data;
  },

  methods: {

    filterContains(element) {
      if (element === null || element === undefined) {
        return false;
      }
      return element.toString().toUpperCase().includes(this.query.toUpperCase());
    },
    tabClicked() {
      // Need to clear query on tab change
      this.query = '';
    },
    editFinish() {
      this.askSave();
      this.editableRow = null;
      this.editCredentialFormVisible = false;
    },
    askSave() {
      this.$alert('Save changes and exit, or continue editing this organization?', 'Save', {
        confirmButtonText: 'Save & Exit',
        showCancelButton: true,
        cancelButtonText: 'Continue',
        callback: action => {
          if (action === 'cancel') {
            this.unsaved = true;
          }
          if (action === 'confirm') {
            this.unsaved = false;
            this.$message({
              type: 'info',
              message: 'Saving changes.',
            });
            this.$emit('save');
          }
        },
      });
    },
    editRow(row) {
      this.query = '';
      this.editableRow = row;
      this.editCredentialFormVisible = true;
    },
    getRules() {
      const rules = {};
      this.credentialFields.forEach(field => {
        rules[field.name] = [{ required: true, message: `${field.label} is required.`, trigger: 'blur' }];
      });
      return rules;
    },
    confirmNewCredential(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          return false;
        }
      });
      this.publishingList.push(this.newCredentialSet);
      this.credentialFormVisible = false;
      this.askSave();
    },
    resetPage() {
      this.pageNum = 1;
    },
    publishSort() {
      return function(a, b) {
        if (a.credentials.name === b.credentials.name) {
          return 0;
        } else if (a.credentials.name === null) {
          return 1;
        } else if (b.credentials.name === null) {
          return -1;
        }
        return a.credentials.name < b.credentials.name ? -1 : 1;
      };
    },
    handlePageChange(pageNum) {
      this.pageNum = pageNum;
    },
    addCredentialSet(partner) {
      this.query = '';
      // First set per partner will be marked and named Default.
      if (this.totalItems > this.pageSize) {
        // Will jump to last page if they add new credentials on paginated credentials
        this.handlePageChange(Math.ceil((this.totalItems + 1) / this.pageSize));
      }
      const existingCredentials = this.publishingList.filter(
        pl => pl.partner_id === partner.id && !pl.remove,
      ).length;

      this.newCredentialSet = {
        id: null,
        remove: false,
        partner: partner.name,
        partner_id: partner.id,
        is_default: !existingCredentials,
        credentials: Object.assign(
          ...partner.credential_fields.map(i => {
            if (i.name === 'name' && !existingCredentials) {
              return { name: 'Default' };
            }
            return { [i.name]: null };
          }),
        ),
      };
      this.credentialFormVisible = true;
    },
    rowClass({ row }) {
      if (row.remove) {
        return 'danger-row';
      }
      return '';
    },
    updateDefault(name, partnerId) {
      for (const item of this.publishingList.filter(
        i => i.partner_id === partnerId,
      )) {
        if (item.credentials.name === name) {
          item.is_default = true;
        } else {
          item.is_default = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-table .el-form-item) {
  margin: 0 !important;
  &.no-label {
    .el-form-item__content {
      margin: 0 !important;
    }
  }
}
:deep(.el-table td) {
  vertical-align: middle !important;
}
.centered {
  text-align: center;
}
:deep(.el-radio__label) {
  display: none;
}
:deep(.el-input-number) {
  width: 100% !important;
  input {
    text-align: left !important;
  }
}
:deep(.el-table td) {
  vertical-align: top;
}
:deep(.el-table__row.danger-row) {
  background: #ffaeae;
}
:deep(.el-table--enable-row-hover .el-table__body tr.danger-row:hover > td) {
  background-color: #eebebe;
}
:deep(.el-switch__label) {
  visibility: hidden;
  &.is-active {
    visibility: visible;
    color: #ff4949;
  }
}

.filter-box {
  width: 400px;
  justify-content: flex-end;
}

.flex-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
}
</style>
