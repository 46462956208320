<template>
  <edit-dialog
    ref="editDialog"
    :custom-rules="customRules"
    :data="user"
    :el-rules="elementRules"
    object-name="User"
    :visible="visible"
    :full="true"
    width="90%"
    @request-close="$emit('update:visible', false)"
    @save="data => $emit('save', data)"
  >
    <template slot-scope="{ formData, error }">
      <div>
        <div v-show="error">
          <el-alert type="error">
            {{ error }}
          </el-alert>
        </div>
        <el-tabs
          v-model="activeTab"
          :before-leave="handleCheckTabChange"
        >
          <el-tab-pane
            label="User Detail"
            name="detail"
          >
            <div class="form">
              <el-form-item
                label="First Name"
                prop="first_name"
              >
                <el-input v-model="formData.first_name" />
              </el-form-item>
              <el-form-item
                label="Last Name"
                prop="last_name"
              >
                <el-input v-model="formData.last_name" />
              </el-form-item>
              <el-form-item
                label="Email"
                prop="email"
              >
                <el-input v-model="formData.email" />
              </el-form-item>
              <el-form-item
                label="Organization"
                prop="organization"
              >
                <org-picker :organization.sync="formData.organization" />
              </el-form-item>
              <el-form-item
                v-if="hasPermission('publish_rtbiq')"
                label="RTBiQ User ID"
                label-width="auto"
                prop="rtbiq_user_id"
              >
                <el-input v-model="formData.rtbiq_user_id" />
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="formData.disabled">
                  Account disabled
                </el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="formData.hide_changelog">
                  Hide Changelog
                </el-checkbox>
              </el-form-item>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Permissions"
            name="perms"
          >
            <div class="permissions">
              <permissions-selector
                :permissions.sync="formData.permissions"
                :user-id="user.id"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </edit-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import validator from 'validator';
import OrgPicker from '../global/OrgPicker.vue';
import EditDialog from '../global/EditDialog.vue';
import PermissionsSelector from './PermissionsSelector';

const whitelistedChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-\' ';

export default {
  components: { EditDialog, OrgPicker, PermissionsSelector },
  props: {
    user: { type: Object, default: () => ({}) },
    visible: { type: Boolean, required: true },
  },

  data() {
    return {
      activeTab: 'detail',
      customRules: {
        permissions: [
          {
            validate(value) {
              return value && value.length > 0;
            },
            errorMessage: 'You must select at least one permission.',
          },
        ],
      },
      elementRules: {
        first_name: [
          {
            required: true,
            message: 'First name is required.',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (value.trim().length < 1) {
                callback(new Error('First name is required.'));
              }
              callback();
            },
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (!validator.isWhitelisted(value, whitelistedChars)) {
                callback(new Error('Names must only contain letters, hyphens, spaces and apostrophes.'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        last_name: [
          {
            required: true,
            message: 'Last name is required.',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (value.trim().length < 1) {
                callback(new Error('Last name is required.'));
              }
              callback();
            },
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (!validator.isWhitelisted(value, whitelistedChars)) {
                callback(new Error('Names must only contain letters, hyphens, spaces and apostrophes.'));
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Email is required.',
            trigger: 'blur',
          },
          {
            validator(rule, value, callback) {
              if (!validator.isEmail(value)) {
                callback(new Error('Invalid email address.'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        organization: [
          {
            validator(rule, value, callback) {
              if (!value) {
                callback(new Error('Please choose an organization.'));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters('user', ['hasPermission']),
  },

  watch: {
    visible(value) {
      if (value) {
        this.activeTab = 'detail';
      }

      this.$refs.editDialog.reset();
    },
  },

  methods: {
    async handleCheckTabChange(newTab, oldTab) {
      if (!oldTab) return true; // first render

      try {
        setTimeout(async () => {
          await this.$refs.editDialog.checkElementRules();
          return true;
        }, 500);
      } catch (e) {
        if (e.message === 'IgnoreMe') throw e;
        this.$reportError(e);
      }
    },
  },
};
</script>

<style scoped>
.el-tabs {
  width: 100%;
}

:deep(.el-tab-pane) {
  height: calc(100vh - 324px);
}

:deep(.el-dialog__footer) {
  margin-top: 3.5em;
}

.el-input {
  width: 320px;
}
</style>
