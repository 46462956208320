<template>
  <div>
    <h3>Administer App Settings</h3>
    <el-tabs
      v-model="activeTab"
      @tab-click="changeTab"
    >
      <el-tab-pane
        label="White Labels"
        name="whitelabels"
      >
        <page-whitelabel v-if="activeTab === 'whitelabels'" />
      </el-tab-pane>
      <el-tab-pane
        label="Settings"
        name="settings"
      >
        <page-settings v-if="activeTab === 'settings'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PageSettings from './settings/PageSettings';
import PageWhitelabel from './settings/PageWhitelabel';

export default {
  components: {
    PageSettings,
    PageWhitelabel,
  },

  data() {
    return {
      activeTab: 'whitelabels',
    };
  },

  methods: {
    changeTab(tab) {
      this.activeTab = tab.name;
    },
  },
};
</script>
