<template>
  <div v-loading.fullscreen.lock="busy">
    <div>
      <el-button
        type="info"
        @click="editingUser = {}; showEditDialog = true"
      >
        <i class="fa fa-plus" /> Add New User
      </el-button>
      <el-button
        type="info"
        :loading="preparingPermissionAudit"
        @click="permissionAudit"
      >
        <i class="fa fa-file-spreadsheet" /> Permission Audit Report
      </el-button>
    </div>

    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="userModel"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      permission-suffix="users"
      @action="handleAction"
      @archive="value => showingArchive = value"
    >
      <div
        slot="additional-controls"
        slot-scope="{ selectedItemCount, archiveView }"
      >
        <el-button
          v-if="hasPermission('administer_users') && !archiveView"
          :disabled="selectedItemCount === 0"
          type="danger"
          @click="handleAction('archiveMulti')"
        >
          Archive Selected Users
        </el-button>
        <el-button
          v-else-if="hasPermission('administer_users') && archiveView"
          :disabled="selectedItemCount === 0"
          type="primary"
          @click="handleAction('archiveMulti', null, { restore: true })"
        >
          Restore Selected Users
        </el-button>
      </div>
      <el-table-column type="expand">
        <template slot-scope="data">
          <h5>Permissions:</h5>
          <el-tag
            v-for="(permission, index) of sortedPerms(data.row.permissions)"
            :key="index"
          >
            {{ permission }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="first_name"
        label="First Name"
        sortable="custom"
      />
      <el-table-column
        prop="last_name"
        label="Last Name"
        sortable="custom"
      />
      <el-table-column
        prop="email"
        label="Email"
        sortable="custom"
      />
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          {{ row.organization.name }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="!showingArchive"
        :width="110"
        prop="created_at"
        label="Created"
        sortable="custom"
      >
        <template slot-scope="data">
          {{ data.row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="disabled"
        label="Status"
        sortable="custom"
        :width="120"
      >
        <template slot-scope="{ row }">
          <span v-if="!row.disabled">Active</span>
          <span v-else>Disabled</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isInternal"
        label="Admin"
        :width="120"
      >
        <template slot-scope="{ row }">
          <span v-if="isAdmin(row)">
            <i class="fa fa-check" />
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isInternal"
        label="Internal"
        :width="120"
      >
        <template slot-scope="{ row }">
          <span v-if="row.internal">
            <i class="fa fa-check" />
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="last_authenticated"
        label="Last Active"
        sortable="custom"
        :width="120"
      >
        <template slot-scope="{ row }">
          {{ row.last_authenticated | dateString }}
        </template>
      </el-table-column>
    </data-table>

    <user-form-dialog
      :user="editingUser"
      :visible.sync="showEditDialog"
      @save="handleSave"
    />
  </div>
</template>

<script>
import uuid from 'uuid';
import { mapGetters } from 'vuex';
import { isEmpty } from '../../helpers';
import { user, permission, reporting } from '@/adonis-api';
import downloadFile from '@/helpers/download-file';
import UserFormDialog from './UserFormDialog.vue';
import DataTable from '../global/DataTable.vue';
import { getValidationErrors } from '@/helpers/validation-rules';
import _find from 'lodash/find';
import _get from 'lodash/get';

export default {
  components: { DataTable, UserFormDialog },

  data() {
    return {
      busy: false,
      dataTable: {
        actions: [
          {
            command: 'edit',
            icon: 'edit',
            label: 'Edit',
            permissions: {
              all: ['administer_users'],
            },
          },
          {
            command: 'impersonate',
            icon: 'id-badge',
            label: 'Impersonate',
            permissions: {
              all: ['impersonate_users'],
            },
          },
        ],
        order: 'ascending',
        sortBy: 'last_name',
      },
      editingUser: {},
      permissionList: [],
      preparingPermissionAudit: false,
      showEditDialog: false,
      showingArchive: false,

    };
  },

  computed: {
    ...mapGetters('user', ['isInternal', 'hasPermission']),
  },

  async created() {
    this.userModel = user;

    const result = await permission.find();
    this.permissionList = result.data;
  },

  methods: {
    sortedPerms(permissions) {
      return permissions.map(perm => _get(_find(this.permissionList, { id: perm }), 'name', null)).sort();
    },

    async handleAction(command, user, options = {}) {
      switch (command) {
        case 'edit':
          this.editingUser = user;
          this.showEditDialog = true;
          break;
        case 'impersonate':
          // TODO: Overwrite vuex user/userDetails with selected user object
          window.mixpanel.track('Impersonate User', {
            'Impersonate User': user.email,
          });
          this.$store.dispatch('auth/impersonateUser', user.id);
          break;
        default:
          break;
        case 'archiveMulti':
          try {
            await this.$confirm(
              `Are you sure you want to ${
                options.restore ? 'restore' : 'archive'
              } the ${
                this.$refs.dataTable.selectedItems.length
              } selected users?`,
              `${options.restore ? 'Restore' : 'Archive'} users`,
              {
                type: 'warning',
              },
            );

            const ids = this.$refs.dataTable.selectedItems.map(row => row.id);

            // Clear row selections
            await this.userModel.bulkDelete(ids, options);

            this.$refs.dataTable.clearSelection();
            this.$refs.dataTable.invokeQuery();
            this.$message('Users archived.');

            window.mixpanel.track('Archive Users', {
              'Archived IDs': ids,
            });
          } catch (e) {
            if (e !== 'cancel') {
              this.$reportError(e);
            }
          }
          break;
      }
    },

    async handleSave(data) {
      data.organization_id = data.organization.id;
      this.busy = true;

      try {
        if (isEmpty(this.editingUser)) {
          await this.userModel.create({
            ...data,
            password: uuid(),
          });
          window.mixpanel.track('Create User', {
            Email: data.email,
            Name: `${data.first_name} ${data.last_name}`,
            Organization: data.organization.name,
          });
        } else {
          await this.userModel.update(data.id, data);
        }

        this.$refs.dataTable.invokeQuery();
        this.showEditDialog = false;
      } catch (e) {
        if (e.response) {
          const errors = getValidationErrors(e);
          if (errors) {
            this.$notify.error({
              message: errors[Object.keys(errors)[0]], // Less strict on the name given in key so as to not have to define each key name here
            });
          }
        }
        this.$reportError(e);
        this.showEditDialog = false;
        this.busy = false;
      } finally {
        this.busy = false;
      }
    },

    isAdmin(user) {
      return user.permissions.includes('administer_users') ||
             user.permissions.includes('administer_organizations');
    },

    async permissionAudit() {
      this.preparingPermissionAudit = true;

      const result = await reporting.getPermissionAudit();

      downloadFile(result.csv_data.join('\n'), {
        filename: `Permission-Audit-${new Date().toISOString().slice(0, 10)}.csv`,
        mimeType: 'text/csv',
        prettify: false,
      });

      this.preparingPermissionAudit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin: 4px;
  background: #dedede;
  color: #666;
}

.cell .el-tag:first-child {
  margin-top: 4px;
}

.search-box {
  max-width: 180px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
