<template>
  <div>
    <el-table
      v-loading="busy"
      :data="whitelabels"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="organization_name"
        label="Organization"
        sortable
      />
      <el-table-column
        prop="domain"
        label="Domain"
        sortable
      />
      <el-table-column
        label="Logo"
      >
        <template slot-scope="scope">
          <img
            class="logo"
            :src="scope.row.logo"
          >
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="{ row }">
          <action-menu
            :actions="menuActions"
            :item="row"
            @action="cmd => whitelabelCommand(cmd, row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <p>
      <el-button
        type="info"
        @click="showDialog = true"
      >
        <i class="fa fa-plus" /> Add White Label
      </el-button>
      <el-button
        v-if="preview"
        @click="removePreview"
      >
        Remove Preview
      </el-button>
    </p>

    <el-dialog
      :visible="showDialog"
      :title="dialogTitle"
      size="small"
      :before-close="() => showDialog = false"
    >
      <whitelabel-form
        :white-label="selected"
        @update="handleWhitelabelUpdate"
      />
    </el-dialog>
  </div>
</template>

<script>
import WhitelabelForm from './WhitelabelForm';
import { whitelabel as whitelabelApi } from '@/adonis-api';
import ActionMenu from '@/components/global/ActionMenu.vue';

export default {
  components: {
    ActionMenu,
    WhitelabelForm,
  },
  data() {
    return {
      busy: false,
      menuActions: Object.freeze([
        // {
        //   command: 'edit',
        //   icon: 'edit',
        //   label: 'Edit',
        // },
        {
          command: 'preview',
          icon: 'eye',
          label: 'Preview',
        },
        {
          command: 'delete',
          icon: 'trash',
          label: 'Delete',
          type: 'danger',
        },
      ]),
      showDialog: false,
      selected: {},
      whitelabels: [],
    };
  },

  computed: {
    dialogTitle() {
      if (this.selected.organization) {
        return 'Edit White Label';
      }

      return 'Add White Label';
    },
    preview() {
      return !!window.location.search;
    },
  },

  async created() {
    this.fetchWhitelabels();
  },

  methods: {
    async fetchWhitelabels() {
      try {
        const response = await whitelabelApi.find();
        this.whitelabels = response.data.whitelabels;
      } catch (e) {
        this.$reportError(e);
      }
    },
    handleWhitelabelUpdate() {
      this.fetchWhitelabels();
      this.showDialog = false;
    },
    removePreview() {
      window.location.search = '';
    },
    async whitelabelCommand(cmd, data, options = {}) {
      switch (cmd) {
        case 'edit':
          this.selected = data;
          this.showDialog = true;
          break;
        case 'preview':
          window.location.search = '?' + data.domain;
          break;
        case 'delete':
          try {
            await this.$confirm(
              'Are you sure you want to delete this whitelabel?',
              'Delete Whitelabel?',
              {
                type: 'warning',
              },
            );
          } catch (e) {
            // User canceled
            return;
          }

          this.busy = true;

          try {
            await whitelabelApi.delete(data.id);
            this.fetchWhitelabels();
          } catch (e) {
            this.$reportError(e);
          } finally {
            this.busy = false;
          }

          break;
        default:
          break;
      }
    },
    // updated(payload) {
    //   let list = [];
    //   if (this.selected.organization) {
    //     // Replace item in list
    //     list = this.whitelabels.map(obj => {
    //       if (_isEqual(obj, this.selected)) {
    //         return payload;
    //       }
    //       return obj;
    //     });
    //   } else {
    //     list = this.whitelabels;
    //     list.push(payload);
    //   }
    //   this.$store.dispatch('settings/whitelabel/setWhitelabels', list);
    //   updateSettings
    //     .callPromise({ settings: JSON.parse(JSON.stringify(this.$store.state.settings)) })
    //     .then(() => this.$message({ message: 'Whitelabel Saved' }));
    //   this.showDialog = false;
    // },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 50px;
}
</style>
